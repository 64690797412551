import React, { useEffect, useState } from 'react'
import { string } from 'prop-types'
import { StaticQuery, graphql, useStaticQuery } from 'gatsby'
import { AdSlot } from 'react-dfp'
import { v4 as uuidv4 } from 'uuid'
import { useDfpSlot } from '../../util/hooks/useDfpSlot'

import * as Styled from './styles/GoogleAdBlock.styles'

// Note: It would be nice if we could query just on one ad here,
// but no go because this is a static query. Second best would be
// querying all these fields in the page query, but that is also
// a no go because this breaks the maximum depth of Prismic Queries
// So, we'll query all the ads, pass the ID up from parent, and filter
// on that in render function. Not ideal.
const ALL_GOOGLE_ADS_QUERY = graphql`
  {
    allPrismicGoogleAdsGlobalTargetingKeyValuePairs {
      edges {
        node {
          id
          data {
            value
            target
            is_enabled
            name
            description
            path_values {
              exclude_
              match_any_string
              path
            }
          }
        }
      }
    }
    allPrismicGoogleAdTag {
      edges {
        node {
          id
          data {
            ad_slot_description
            ad_slot_name
            out_of_page_interstitial_ad_slot
            body {
              ... on PrismicGoogleAdTagDataBodyResponsiveSize {
                id
                primary {
                  breakpoint_width {
                    document {
                      ... on PrismicGoogleAdBreakpoint {
                        data {
                          browser_width
                        }
                      }
                    }
                  }
                }
                items {
                  ad_size {
                    document {
                      ... on PrismicGoogleAdSizeOption {
                        id
                        data {
                          name
                          height
                          width
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const yesOrNoFromBool = inputBool => (inputBool ? 'Yes' : 'No')

export const getTargetingParameters = (fullPath, kvpNodes) => {
  // Enter one path per line. ~ means exclude. * means any string of characters.
  if (!fullPath) {
    return null
  }

  const urlPath = fullPath.split('/').pop()

  // pass through all nodes with no path values
  const emptyNodes = kvpNodes.filter(a => a.node.data.path_values.length === 0)

  // find all nodes that contain only inclusions,
  // then pass through any that match the path
  const includeNodes = kvpNodes
    .filter(a => a.node.data.path_values.every(p => p.exclude_ === false))
    .filter(a => a.node.data.path_values.some(p => urlPath.includes(p.path)))

  // find all nodes that contain only exclusions,
  // then pass through only those with no path matches
  const excludeNodes = kvpNodes
    .filter(a => a.node.data.path_values.every(p => p.exclude_ === true))
    .filter(a => !a.node.data.path_values.some(p => urlPath.includes(p.path)))

  // find all nodes with mixed inclusions and exclusions,
  // then pass through all matching inclusions that aren't
  // specifically excluded, using partial path matches
  const mixedNodes = kvpNodes
    .filter(
      a =>
        a.node.data.path_values.length > 1 &&
        a.node.data.path_values.find(p => p.exclude_ === true) !== undefined &&
        a.node.data.path_values.find(p => p.exclude_ === false) !== undefined
    )
    .filter(a =>
      a.node.data.path_values.some(
        p => urlPath.includes(p.path) && p.exclude_ === false
      )
    )
    .filter(
      a =>
        !a.node.data.path_values.some(
          p => urlPath.includes(p.path) && p.exclude_ === true
        )
    )

  const rules = emptyNodes
    .concat(includeNodes)
    .concat(excludeNodes)
    .concat(mixedNodes)
    .map(p => p.node)

  const targets = {}
  rules.forEach(
    rule => (targets[[rule.data.target]] = yesOrNoFromBool(rule.data.value))
  )
  return targets
}

const getSizeMapping = responsiveSizes => {
  const mappings = responsiveSizes.map(responsiveSize => {
    const breakpoint = responsiveSize.primary.breakpoint_width?.document?.data
      ?.browser_width
      ? responsiveSize.primary.breakpoint_width.document.data.browser_width
      : 0
    const adSizes = responsiveSize.items.map(adSize => [
      adSize.ad_size.document.data.width,
      adSize.ad_size.document.data.height,
    ])
    return { viewport: [breakpoint, 0], sizes: adSizes }
  })
  return mappings
}

const getSizes = responsiveSizes =>
  responsiveSizes
    .map(responsiveSize =>
      responsiveSize.items.map(adSize => [
        adSize.ad_size.document.data.width,
        adSize.ad_size.document.data.height,
      ])
    )
    .slice(0, 1)
    .pop()

const GoogleAdBlock = ({ id, className, bottomPaddingAdjustment }) => {
  const data = useStaticQuery(ALL_GOOGLE_ADS_QUERY)
  const [adSlotName, setAdSlotName] = useState(null)
  const [isOutOfPageInterstitial, setIsOutOfPageInterstitial] = useState(null)
  const [sizeMapping, setSizeMapping] = useState(null)
  const [sizes, setSizes] = useState(null)
  const [kvpNopdes, setKvpNodes] = useState(null)
  const [targets, setTargets] = useState(null)
  const [divId, setDivId] = useState(null)

  const path =
    typeof window !== 'undefined'
      ? window.location.pathname.replace(/\//g, '')
      : ''

  useEffect(() => {
    if (!data || !id) return
    const googleAdTag = data?.allPrismicGoogleAdTag?.edges
      ?.filter(tag => tag.node.id === id)
      ?.slice(0, 1)
      .pop().node.data
    if (!googleAdTag) return

    setAdSlotName(googleAdTag.ad_slot_name)
    setIsOutOfPageInterstitial(googleAdTag.out_of_page_interstitial_ad_slot)
    const responsiveSizes = googleAdTag.body
    setSizeMapping(getSizeMapping(responsiveSizes))
    setSizes(getSizes(responsiveSizes))
    const kvpNodes = data.allPrismicGoogleAdsGlobalTargetingKeyValuePairs.edges
    setTargets(getTargetingParameters(path, kvpNodes))
    setDivId(uuidv4())
    return () => {
      // Cleanup function (if necessary)
    }
  }, [data])

  // if (!data || !adSlotName || !divId) return null

  useDfpSlot({
    path: `/13970653/${adSlotName}`,
    size: sizes,
    sizeMapping: sizeMapping,
    id: divId,
    targets: targets,
    enableLazyLoad: adSlotName === 'CH01_BottomoBanner' ? true : false,
  })

  return (
    // <pre>OK</pre>
    <Styled.GoogleAdBlock
      className={className}
      bottomPaddingAdjustment={bottomPaddingAdjustment}
      id={divId}
    />
  )
}

export default GoogleAdBlock
