import styled from 'styled-components'
import { Link } from 'gatsby'

import { DEVICE } from '../../../util/constants'
import { HeroH1, HeroP } from '../../styles/Utility.styles'

export const BackButtonContainer = styled(Link)`
  grid-column: 2 / 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration-line: none;
  margin-top: 41px;

  svg {
    margin-right: 20px;
  }

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.357;
    text-transform: uppercase;
    color: var(--content-background-dark-blue);
  }

  @media ${DEVICE.tabletL} {
    span {
      font-size: 1.4rem;
    }
  }
`

export const HeroTitle = styled(HeroH1)`
  grid-column: 2 / 3;
  padding: 14px 0;

  @media ${DEVICE.tabletL} {
    padding: 14px 0 20px;
  }
`

export const HeroBody = styled(HeroP)`
  grid-column: 2 / 3;

  h2 {
    padding: 20px 0 4px;
    line-height: 1.36;
    font-weight: 300;

    @media ${DEVICE.tabletL} {
      padding: 40px 0 4px;
    }
  }
`

export const ClinicDetailsSection = styled.section`
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  padding-top: 12px;

  @media ${DEVICE.tabletL} {
    padding-top: 30px;
  }
`

export const ClinicDetailsGroup = styled.div`
  /* Not applicable on Mobile */
  @media ${DEVICE.tabletL} {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
  }

  @media screen and (min-width: 900px) {
    margin: 0 40px -37px 80px;
  }
`

export const PrimaryClinicDetailsGroup = styled(ClinicDetailsGroup)`
  @media ${DEVICE.tabletL} {
    flex-wrap: nowrap;
  }

  @media screen and (min-width: 900px) {
    margin: 0 40px -37px 80px;
  }
`

export const TelehealthRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 13px;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: 0.02em;
  color: var(--content-background-dark-blue);

  svg {
    margin: 0 10px 0 0;
  }
`

export const Divider = styled.hr`
  /* Not applicable on Mobile */
  display: none;

  @media ${DEVICE.tabletL} {
    display: unset;
    border: 1px solid var(--content-background-dark-blue);
    margin: 17px 0 30px;
  }

  @media screen and (min-width: 900px) {
    margin: 17px 80px 30px;
  }
`

export const CategoryColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 192px;
  margin-bottom: 10px;

  @media ${DEVICE.tabletL} {
    /* row-wrap hack */
    margin-bottom: 37px;
    padding-right: 20px;
  }
`

export const PracticeInfoColumn = styled(CategoryColumn)`
  flex-basis: 228px;
  padding-right: 40px;

  @media ${DEVICE.tabletL} {
    flex-basis: 340px;
  }

  @media ${DEVICE.laptopS} {
    flex-basis: 340px;
  }
`

export const CategoryHeading = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.33;
  align-items: center;
  color: var(--highlight-blue);

  @media ${DEVICE.tabletL} {
    font-size: 1.4rem;
    line-height: 1.71;
    letter-spacing: 0.02em;
  }
`

export const CategoryList = styled.ul`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.375;
  color: var(--content-background-dark-blue);
  padding-top: 4px;

  li {
    margin: 10px 0;

    img {
      width: 150px;
    }

    :first-of-type {
      margin-top: 6px;
    }
  }

  @media ${DEVICE.tabletL} {
    font-size: 1.6rem;
  }
`

export const PracticeName = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.375;
  color: var(--content-background-dark-blue);
  margin-top: 14px;
`

export const PracticeContactDetailBlock = styled.div`
  margin: 10px 0;

  p,
  a {
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.5;
    text-decoration-line: none;
    color: var(--content-background-dark-blue);
  }

  a {
    :hover {
      text-decoration-line: underline;

      p {
        color: var(--highlight-blue);
      }
    }
  }

  @media ${DEVICE.tabletL} {
    :first-of-type {
      margin-top: 6px;
    }

    :last-of-type {
      margin-top: 20px;
    }
  }
`

export const TypeAndAcceptingColumn = styled(PracticeInfoColumn)`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`

export const Disclaimer = styled.div`
  font-style: italic;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.6;
  color: var(--content-background-dark-blue);
  padding-top: 20px;

  @media ${DEVICE.tabletL} {
    font-size: 1.8rem;
  }
`
